import {
  TBookCategories,
  TBookParties,
  TBookPaymentModes,
  TInvolvedUser,
  useBook,
} from "./books"
import { Timestamp, where, writeBatch } from "firebase/firestore"
import { useFormik } from "formik"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import { useCallback, useMemo, useRef, useEffect, useReducer } from "react"
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
  useFunctions,
  useUser,
} from "reactfire"
import { $PropertyType } from "utility-types"
import { TBook, isSharedBook, useBookDocument } from "./books"
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  query,
  orderBy,
  deleteDoc,
} from "firebase/firestore"
import type { CollectionReference } from "firebase/firestore"
import {
  areIntervalSameDay,
  getThisDayInterval,
  getYesterdayInterval,
  getThisMonthInterval,
  getLastMonthInterval,
  isAfterDate,
  isBeforeDate,
  timeStampToDate,
  dateToTimestamp,
  formatDate,
  getServerTimestamp,
  isSameSecond,
  isSameDay,
} from "@cashbook/util-dates"
import { httpsCallable } from "firebase/functions"
import { useAsyncFetchWithStatus } from "@cashbook/util-general"
import { useParams } from "react-router-dom"

export type EntryAttachmentInfo = {
  url: string
  thumbUrl?: string
  mimeType: string
  fileName: string
  id: string
  addedAt?: Timestamp
}

export type ExtendedTransaction = {
  [key: string]: string | undefined
} & TTransaction

export type TTransaction = {
  id: string
  amount: number
  createdAt?: Timestamp
  /**
   * Id of the creator
   */
  createdBy?: string
  /**
   * Full details of the creator
   */
  createdByMember?: TInvolvedUser
  date: Timestamp
  remark?: string | null
  type: "cash-in" | "cash-out"
  updatedAt?: Timestamp
  /**
   * Id of the updater
   */
  updatedBy?: string
  /**
   * Full details of the updater
   */
  updatedByMember?: TInvolvedUser
  imageUrl?: string | null
  thumbUrl?: string | null
  cashBookId: string
  partyId?: string | null
  party?: TBookParties[number]
  paymentModeId?: string | null
  paymentMode?: TBookPaymentModes[number]
  categoryId?: string | null
  category?: TBookCategories[number]
  sharable?: boolean
  smsBookEntryId?: string
  sourceLoc?: "cashbook-payments"
  attachments?: { [id: string]: EntryAttachmentInfo }
}

export type LogTransactionDataSchema = Pick<
  TTransaction,
  | "remark"
  | "type"
  | "amount"
  | "imageUrl"
  | "thumbUrl"
  | "attachments"
  | "partyId"
  | "categoryId"
  | "paymentModeId"
> & {
  date: Date
  usingCalculator: boolean
  usingSaveAndNext?: boolean
  categoryFromSuggestions?: boolean
  paymentModeFromSuggestions?: boolean
  sharable?: boolean
}

export type TTransactionFilterParams = {
  q?: string
  type?: "cash-in" | "cash-out"
  afterDate?: Date
  beforeDate?: Date
  entryBy?: { id: string; name: string; phoneNumber: string }
  categories?: TBookCategories
  paymentModes?: TBookPaymentModes
  parties?: TBookParties
  isOpeningBalanceEnabled?: boolean
}

export function useTransactionsCollection(bookId: string) {
  const bookDoc = useBookDocument(bookId)
  return collection(
    bookDoc,
    "Transactions"
  ) as CollectionReference<TTransaction>
}

function useTransactionDocument(bookId: string, transactionId: string) {
  const collection = useTransactionsCollection(bookId)
  return doc(collection, transactionId)
}

export function useTransactions(book: TBook) {
  const transactionsCollection = useTransactionsCollection(book.id)
  const { data: user } = useUser()
  const { hideEntriesByOthers, authMemberDetails } = useBook(book.id)
  const userRole = authMemberDetails.role.id
  let transactionsCollectionQuery = query(
    transactionsCollection,
    orderBy("date", "desc")
  )
  if (userRole === "editor" && hideEntriesByOthers) {
    transactionsCollectionQuery = query(
      transactionsCollection,
      orderBy("date", "desc"),
      where("createdBy", "==", user?.uid || "")
    )
  }
  const { data: transactions } = useFirestoreCollectionData(
    transactionsCollectionQuery,
    { idField: "id" }
  )
  const resolveAttributesFromBook = useCallback(
    (transaction: TTransaction) => {
      return resolveTransactionAttributesFromBook(book, transaction)
    },
    [book]
  )

  const isUpdated = useCallback(
    (transaction: TTransaction) => isTransactionUpdated(transaction),
    []
  )
  return {
    transactions,
    isUpdated,
    resolveAttributesFromBook,
    resolveTransactionAttributesFromInvolvedUsers,
  }
}

export function useAddTransaction(book: TBook) {
  const transactionsCollection = useTransactionsCollection(book.id)
  const { data: user } = useUser()
  return useCallback(
    async function addTransaction({
      usingCalculator,
      usingSaveAndNext,
      paymentModeFromSuggestions,
      categoryFromSuggestions,
      ...data
    }: LogTransactionDataSchema) {
      const doc = await addDoc(transactionsCollection, {
        ...data,
        date: dateToTimestamp(data.date),
        createdAt: getServerTimestamp(),
        createdBy: user?.uid,
        cashBookId: book.id,
      } as never)
      if (data.type === "cash-in") {
        trackEvent(TrackingEvents.CASH_IN_ENTRY_DONE, {
          id: doc.id,
          type: "cash-in",
          date: data.date.toISOString(),
          amount: data.amount,
          remark: data.remark,
          billAttached: Boolean(data.imageUrl || data.thumbUrl),
          category: data.categoryId
            ? book.categoriesById[data.categoryId]?.name
            : undefined,
          paymentMode: data.paymentModeId
            ? book.paymentModesById[data.paymentModeId]?.name
            : undefined,
        })
      } else {
        trackEvent(TrackingEvents.CASH_OUT_ENTRY_DONE, {
          id: doc.id,
          type: "cash-out",
          date: data.date.toISOString(),
          amount: data.amount,
          remark: data.remark,
          billAttached: Boolean(data.imageUrl || data.thumbUrl),
          category: data.categoryId
            ? book.categoriesById[data.categoryId]?.name
            : undefined,
          paymentMode: data.paymentModeId
            ? book.paymentModesById[data.paymentModeId]?.name
            : undefined,
        })
      }
      trackEvent(TrackingEvents.ENTRY_DONE, {
        type: data.type,
        sharedBook: isSharedBook(book),
        usingCalculator,
        usingSaveAndNext: Boolean(usingSaveAndNext),
        categoryUsed: Boolean(data.categoryId),
        paymentModeUsed: Boolean(data.paymentModeId),
        categoryFromSuggestions:
          categoryFromSuggestions || (data.categoryId ? false : undefined),
        paymentModeFromSuggestions:
          paymentModeFromSuggestions ||
          (data.paymentModeId ? false : undefined),
        partyUsed: Boolean(data.partyId),
        sharable: Boolean(data.sharable),
        numOfAttachments: Object.keys(data.attachments || {}).length,
      })
      return doc
    },
    [book, transactionsCollection, user?.uid]
  )
}

export function useDeleteTransactions(book: TBook) {
  const store = useFirestore()
  const bookTransactionsCollection = useTransactionsCollection(book.id)

  const deleteTransaction = useCallback(
    async (transaction: TTransaction) => {
      const transactionDocument = doc(
        bookTransactionsCollection,
        transaction.id
      )
      await deleteDoc(transactionDocument)
      trackEvent(TrackingEvents.DELETE_ENTRY, {
        sharedBook: isSharedBook(book),
        billAttached: Boolean(transaction.imageUrl),
        isEdited: isTransactionUpdated(transaction),
        synced: true,
        from: "singleEntry",
        entryCount: 1,
      })
    },
    [book, bookTransactionsCollection]
  )

  const deleteTransactions = useCallback(
    async (transactions: string[]) => {
      try {
        const batches = [writeBatch(store)]
        let documentOperationsCounts = 0
        transactions.forEach((tId) => {
          const operationsInThisTransaction = 1
          if (documentOperationsCounts + operationsInThisTransaction > 50) {
            batches.push(writeBatch(store))
            documentOperationsCounts = 0
          }
          documentOperationsCounts += operationsInThisTransaction
          batches[batches.length - 1].delete(
            doc(bookTransactionsCollection, tId)
          )
        })
        trackEvent(TrackingEvents.DELETE_ENTRY, {
          sharedBook: isSharedBook(book),
          synced: true,
          from: "multiEntry",
          entryCount: transactions.length,
        })
        await Promise.all(batches.map((batch) => batch.commit()))
      } catch (e) {
        const err = e as Error
        throw err
      }
    },
    [book, bookTransactionsCollection, store]
  )
  return {
    deleteTransaction,
    deleteTransactions,
  }
}

/**
 * This hook will hold a reference to the transaction even after transaction deletion
 */
export function useEnsuredTransaction(bookId: string, transactionId: string) {
  const transactionDocument = useTransactionDocument(bookId, transactionId)
  const { data: transactionData } = useFirestoreDocData<TTransaction>(
    transactionDocument,
    {
      idField: "id",
    }
  )
  // Create a ref of current data and pass it down.
  // This allows us to handle the transaction deletion
  const transactionRef = useRef(transactionData)
  let isDeleted = false
  if (transactionData && transactionData.id) {
    // always keep the data in sync
    transactionRef.current = transactionData
  } else {
    isDeleted = true
  }
  if (!transactionRef.current) {
    throw new Error("Transaction not found")
  }
  return { transaction: transactionRef.current, isDeleted }
}

export function useTransaction(book: TBook, transactionId: string) {
  const transactionDocument = useTransactionDocument(book.id, transactionId)
  const { transaction, isDeleted } = useEnsuredTransaction(
    book.id,
    transactionId
  )
  const { data: user } = useUser()
  async function updateTransaction({
    usingCalculator,
    paymentModeFromSuggestions,
    categoryFromSuggestions,
    ...data
  }: LogTransactionDataSchema) {
    const previousDate = timeStampToDate(transaction.date)
    const previousRemark = transaction.remark
    const previousAmount = transaction.amount
    const previousBillImageUrl = transaction.imageUrl
    const previousCategoryId = transaction.categoryId || null
    const previousPartyId = transaction.partyId || null
    const previousPaymentModeId = transaction.paymentModeId || null
    await updateDoc(transactionDocument, {
      ...data,
      date: dateToTimestamp(data.date),
      updatedAt: getServerTimestamp(),
      updatedBy: user?.uid,
    } as never)
    trackEvent(TrackingEvents.EDIT_ENTRY, {
      sharedBook: isSharedBook(book),
      usingCalculator,
      dateChanged:
        formatDate(previousDate, "yyyy-MM-dd") !==
        formatDate(data.date, "yyyy-MM-dd"),
      timeChanged:
        formatDate(previousDate, "hh:mm") !== formatDate(data.date, "hh:mm"),
      remarkChanged:
        (data.remark || "").trim() !== (previousRemark || "").trim(),
      amountChanged: data.amount !== previousAmount,
      billChanged: data.imageUrl !== previousBillImageUrl,
      entryBySelf: transaction.createdBy === user?.uid,
      categoryChanged: data.categoryId !== previousCategoryId,
      paymentModeChanged: data.paymentModeId !== previousPaymentModeId,
      paymentModeFromSuggestions,
      categoryFromSuggestions,
      partyChanged: data.partyId !== previousPartyId,
    })
  }
  const isEdited = useMemo(
    () => isTransactionUpdated(transaction),
    [transaction]
  )
  resolveTransactionAttributesFromBook(book, transaction)
  const resolveAttributesFromInvolvedUsers = useCallback(
    (involvedUsers: Array<TInvolvedUser>) => {
      resolveTransactionAttributesFromInvolvedUsers(involvedUsers, transaction)
    },
    [transaction]
  )
  return {
    transaction,
    isDeleted,
    updateTransaction,
    isCashIn: transaction.type === "cash-in",
    isCashOut: transaction.type === "cash-out",
    isBillAttached: Boolean(transaction.imageUrl),
    resolveAttributesFromInvolvedUsers,
    isEdited,
  }
}

export function isTransactionUpdated(transaction: TTransaction) {
  const { createdAt, updatedAt } = transaction
  return Boolean(
    createdAt &&
      updatedAt &&
      !isSameSecond(timeStampToDate(createdAt), timeStampToDate(updatedAt))
  )
}

export function resolveTransactionAttributesFromBook(
  book: TBook,
  transaction: TTransaction
) {
  const { paymentModesById, categoriesById, partiesById } = book
  transaction.paymentMode = transaction.paymentModeId
    ? paymentModesById[transaction.paymentModeId]
    : undefined
  transaction.category = transaction.categoryId
    ? categoriesById[transaction.categoryId]
    : undefined
  transaction.party = transaction.partyId
    ? partiesById[transaction.partyId]
    : undefined
  return transaction
}

export function resolveTransactionAttributesFromInvolvedUsers(
  involvedUsers: Array<TInvolvedUser>,
  transaction: TTransaction
) {
  if (
    transaction.createdBy &&
    transaction.createdByMember?.id !== transaction.createdBy
  ) {
    transaction.createdByMember = involvedUsers.find(
      (u) => u.id === transaction.createdBy
    )
  }
  if (
    transaction.updatedBy &&
    transaction.updatedByMember?.id !== transaction.updatedBy
  ) {
    transaction.updatedByMember = involvedUsers.find(
      (u) => u.id === transaction.updatedBy
    )
  }
  return transaction
}

const InitialParams: TTransactionFilterParams = {
  q: "",
  type: undefined,
  afterDate: undefined,
  beforeDate: undefined,
  entryBy: undefined,
  parties: [],
  categories: [],
  paymentModes: [],
  isOpeningBalanceEnabled: true,
}

const LIMIT = 50

export function useTransactionsSearch(
  book: TBook,
  initialSearchParamsProp: TTransactionFilterParams = InitialParams,
  businessRole: "owner" | "partner" | "staff"
) {
  const initialSearchParams = useMemo(() => {
    if (!initialSearchParamsProp) return InitialParams
    return {
      ...InitialParams,
      ...initialSearchParamsProp,
      q: initialSearchParamsProp.q || InitialParams.q,
    }
  }, [initialSearchParamsProp])
  const {
    transactions: baseTransactions,
    resolveAttributesFromBook,
    isUpdated,
  } = useTransactions(book)
  const {
    values: params,
    handleChange: handleParamChange,
    setFieldValue,
    setValues,
  } = useFormik<TTransactionFilterParams>({
    initialValues: initialSearchParams,
    onSubmit: () => undefined,
  })
  const hasAppliedFilters = useMemo(() => {
    const {
      q,
      type,
      afterDate,
      beforeDate,
      entryBy,
      parties,
      categories,
      paymentModes,
    } = params
    return Boolean(
      q?.trim() ||
        type ||
        afterDate ||
        beforeDate ||
        entryBy ||
        parties?.length ||
        categories?.length ||
        paymentModes?.length
    )
  }, [params])

  const hasFilterAppliedOtherThanDate = useMemo(() => {
    const { q, type, entryBy, parties, categories, paymentModes } = params
    return Boolean(
      q?.trim() ||
        type ||
        entryBy ||
        parties?.length ||
        categories?.length ||
        paymentModes?.length
    )
  }, [params])

  const {
    q,
    type,
    afterDate,
    beforeDate,
    entryBy,
    parties,
    categories,
    paymentModes,
    isOpeningBalanceEnabled,
  } = params
  const isThisBookShared = isSharedBook(book)

  let numberOfFiltersApplied =
    (q?.trim() ? 1 : 0) +
    (type ? 1 : 0) +
    (afterDate || beforeDate ? 1 : 0) +
    (entryBy ? 1 : 0) +
    (parties?.length ? 1 : 0) +
    (categories?.length ? 1 : 0) +
    (paymentModes?.length ? 1 : 0)

  const { businessId } = useParams()

  useEffect(() => {
    if (numberOfFiltersApplied) {
      trackEvent(TrackingEvents.APPLY_FILTER, {
        type:
          type === "cash-in"
            ? "Cash In"
            : type === "cash-out"
            ? "Cash Out"
            : undefined,
        member: entryBy ? 1 : 0,
        sharedBook: isThisBookShared,
        from: "filterIcon",
        partiesCount: parties?.length || 0,
        categoriesCount: categories?.length || 0,
        paymentModeCount: paymentModes?.length || 0,
        role: businessRole,
        businessId: businessId || "",
        includesOpeningBalance: Boolean(isOpeningBalanceEnabled),
      })
    }
  }, [
    numberOfFiltersApplied,
    type,
    entryBy,
    afterDate,
    parties,
    categories,
    paymentModes,
    businessRole,
    businessId,
    isThisBookShared,
    isOpeningBalanceEnabled,
  ])

  useEffect(() => {
    if (afterDate || beforeDate) {
      const resolvedDateIntervals = resolveAppliedDateInterval([
        afterDate,
        beforeDate,
      ])
      trackEvent(TrackingEvents.DATE_FILTER_CLICKED)
      trackEvent(TrackingEvents.DATE_FILTER_APPLIED, {
        role: businessRole,
        businessId: businessId || "",
        dateFilter: resolvedDateIntervals.dateFilterAppliedType,
        includesOpeningBalance: Boolean(isOpeningBalanceEnabled),
      })
    }
  }, [afterDate, beforeDate, businessId, businessRole, isOpeningBalanceEnabled])

  const [transactions, openingBalance] = useMemo((): [
    transactions: Array<TTransaction>,
    openingBalance: number
  ] => {
    if (!hasAppliedFilters) return [baseTransactions, 0]
    return filterWithOpeningBalance(baseTransactions, params)
  }, [baseTransactions, params, hasAppliedFilters])

  const overview = useTransactionsOverview(transactions, openingBalance)

  const {
    current: currentPage,
    next,
    pagination,
    lastPage,
    reset,
    previous,
    setPage,
  } = usePagination({
    skip: 0,
    take: LIMIT,
    totalItems: hasAppliedFilters
      ? transactions.length
      : baseTransactions.length,
  })

  const loadMore = useCallback(() => {
    next()
  }, [next])

  // reset the limit when params changes
  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const limitedTransactions = useMemo(() => {
    return transactions.slice(pagination.skip, pagination.take * currentPage)
  }, [currentPage, pagination.skip, pagination.take, transactions])

  return {
    totalTransactions: baseTransactions.length,
    totalFilteredTransactions: transactions.length,
    transactions: limitedTransactions,
    hasMoreTransactions: limitedTransactions.length < transactions.length,
    params,
    openingBalance,
    resetParams: () => {
      numberOfFiltersApplied = 0
      setValues(InitialParams)
    },
    setParamValue: setFieldValue,
    handleParamChange,
    hasAppliedFilters,
    hasFilterAppliedOtherThanDate,
    numberOfFiltersApplied: numberOfFiltersApplied,
    loadMoreTransactions: loadMore,
    allTransactions: transactions,
    resolveAttributesFromBook,
    isUpdated,
    resolveTransactionAttributesFromInvolvedUsers,
    ...overview,

    //Pagination
    lastPage,
    pagination,
    currentPage,
    setPage,
    next,
    previous,
  }
}

export function useTransactionsOverview(
  transactions: Array<TTransaction>,
  openingBalance: number
) {
  return useMemo(() => {
    return getTransactionsSummary(transactions, {
      opening: openingBalance,
    })
  }, [transactions, openingBalance])
}

export function getTransactionsSummary(
  transactions: Array<TTransaction>,
  { opening }: { opening: number } = { opening: 0 }
) {
  return transactions
    .concat([])
    .reverse()
    .reduce<{
      totalCashIn: number
      totalCashOut: number
      closingBalance: number
      closingBalancePerTransaction: { [transactionId: string]: number }
    }>(
      (
        {
          totalCashIn,
          totalCashOut,
          closingBalance,
          closingBalancePerTransaction,
        },
        transaction
      ) => {
        const amount =
          transaction.type === "cash-in"
            ? transaction.amount
            : -1 * transaction.amount
        closingBalancePerTransaction[transaction.id] = closingBalance + amount
        return {
          totalCashIn: totalCashIn + (amount > 0 ? transaction.amount : 0),
          totalCashOut: totalCashOut + (amount < 0 ? transaction.amount : 0),
          closingBalance: closingBalance + amount,
          closingBalancePerTransaction,
        }
      },
      {
        totalCashIn: 0,
        totalCashOut: 0,
        closingBalance: opening,
        closingBalancePerTransaction: {},
      }
    )
}

export function filterWithOpeningBalance(
  transactions: Array<TTransaction>,
  params: TTransactionFilterParams
): [transactions: Array<TTransaction>, openingBalance: number] {
  const {
    q,
    type,
    afterDate,
    beforeDate,
    entryBy,
    parties,
    categories,
    paymentModes,
    isOpeningBalanceEnabled,
  } = params
  let openingBalance = 0
  const filtered = transactions.filter((transaction) => {
    if (afterDate) {
      if (isBeforeDate(timeStampToDate(transaction.date), afterDate)) {
        // this become part of openingBalance
        if (
          isOpeningBalanceEnabled &&
          !(
            entryBy?.id ||
            parties?.length ||
            q?.length ||
            type?.length ||
            categories?.length ||
            paymentModes?.length
          )
        ) {
          openingBalance +=
            transaction.type === "cash-in"
              ? transaction.amount
              : -1 * transaction.amount
        }
        return false
      }
    }
    if (beforeDate) {
      if (isAfterDate(timeStampToDate(transaction.date), beforeDate)) {
        return false
      }
    }
    if (type && transaction.type !== type) {
      return false
    }
    if (entryBy && transaction.createdBy !== entryBy.id) {
      return false
    }
    if (
      parties?.length &&
      !parties.find((c) => c.uuid === transaction.partyId)
    ) {
      return false
    }
    if (
      categories?.length &&
      !categories.find((c) => c.uuid === transaction.categoryId)
    ) {
      return false
    }
    if (
      paymentModes?.length &&
      !paymentModes.find((c) => c.uuid === transaction.paymentModeId)
    ) {
      return false
    }
    if (
      q?.trim() &&
      !(transaction.remark || "").toLowerCase().includes(q.toLowerCase()) &&
      !String(transaction.amount || "")
        .toLowerCase()
        .includes(q.toLowerCase())
    ) {
      return false
    }
    return true
  })
  return [filtered, openingBalance]
}

export function getStaticDateIntervals(relativeTo?: Date) {
  return [
    { interval: getThisDayInterval(relativeTo), label: "Today" as const },
    { interval: getYesterdayInterval(relativeTo), label: "Yesterday" as const },
    {
      interval: getThisMonthInterval(relativeTo),
      label: "This Month" as const,
    },
    {
      interval: getLastMonthInterval(relativeTo),
      label: "Last Month" as const,
    },
  ]
}

export function resolveAppliedDateInterval(
  appliedInterval: [afterDate: Date | undefined, beforeDate: Date | undefined]
) {
  const intervals = getStaticDateIntervals(new Date())
  const [afterDate, beforeDate] = appliedInterval
  let allTimeApplied = true
  let customDateApplied = false
  let label:
    | $PropertyType<ReturnType<typeof getStaticDateIntervals>[number], "label">
    | "All Time"
    | "Custom" = "All Time"
  let dateFilterAppliedType:
    | $PropertyType<ReturnType<typeof getStaticDateIntervals>[number], "label">
    | "All Time"
    | "Single Day"
    | "Date Range" = "All Time"
  // NOTE: We will mutate this object's isApplied status
  const intervalsWithAppliedStatus = intervals.map((i) => ({
    ...i,
    isApplied: false,
  }))
  if (afterDate || beforeDate) {
    allTimeApplied = false
    customDateApplied = true
    label = "Custom"

    if (afterDate && beforeDate && isSameDay(afterDate, beforeDate)) {
      dateFilterAppliedType = "Single Day"
    } else {
      dateFilterAppliedType = "Date Range"
    }
    for (const interval of intervalsWithAppliedStatus) {
      const isThisIntervalApplied =
        afterDate && beforeDate
          ? areIntervalSameDay([afterDate, beforeDate], interval.interval)
          : false
      if (isThisIntervalApplied) {
        label = interval.label
        dateFilterAppliedType = interval.label
        customDateApplied = false
        // NOTE: we are mutating the object here
        interval.isApplied = true
      }
    }
  }
  return {
    intervalsWithAppliedStatus,
    customDateApplied,
    allTimeApplied,
    appliedDateIntervalLabel: label,
    dateFilterAppliedType,
  }
}

export function useTransferEntries() {
  const fns = useFunctions()
  return useCallback(
    async function transfer(data: {
      type: "copy" | "move" | "opposite"
      srcBookId: string
      destBookId: string
      transactionIds: Array<string>
      keepOriginalCreator: boolean
    }) {
      await httpsCallable(fns, "transferEntries")(data)
    },
    [fns]
  )
}

type BillDetailsData = {
  id: string
  amount: string
  date: string
  type: "cash-in" | "cash-out"
  remark: string
  party: {
    id: string
    name: string
    phoneNumber: string
    type: "customer" | "supplier"
  }
  user: {
    displayName: string
    phoneNumber: string
  }
  paymentMode: {
    uuid: string
    name: string
  }
}

export function useBillDetailsOverview(token: string | null) {
  const fns = useFunctions()
  if (!token) {
    throw new Error("Missing bill token")
  }
  const fetcher = useCallback(async () => {
    const { data } = await httpsCallable<{ token: string }, BillDetailsData>(
      fns,
      "getSharedEntry"
    )({
      token,
    })
    return data
  }, [fns, token])
  const { error, status, data, fetchData } = useAsyncFetchWithStatus(fetcher)
  useEffect(() => {
    fetchData()
  }, [fetchData])
  return {
    data,
    error,
    status,
  }
}

type PaginationState = {
  skip: number
  take: number
  current: number
  lastPage: number
}
type TYPE_AND_PAYLOAD =
  | { type: "SET_UP_PAGINATION"; payload: { skip: number; take: number } }
  | { type: "UPDATE_LAST_PAGE"; payload: number }
  | { type: "RESET_PAGINATION"; payload: PaginationState }
  | { type: "SET_PAGE"; payload: { page: number; skip: number } }
  | { type: "UPDATE_PAGINATION"; payload: PaginationState }

const reducer = (
  state: PaginationState,
  action: TYPE_AND_PAYLOAD
): PaginationState => {
  switch (action.type) {
    case "SET_UP_PAGINATION":
      return {
        ...state,
        skip: action.payload.skip,
        take: action.payload.take,
      }
    case "UPDATE_LAST_PAGE":
      return {
        ...state,
        lastPage: action.payload,
      }
    case "UPDATE_PAGINATION":
      return {
        ...action.payload,
      }
    case "SET_PAGE":
      return {
        ...state,
        skip: action.payload.skip,
        current: action.payload.page,
      }
    case "RESET_PAGINATION":
      return {
        ...action.payload,
      }
    default:
      return state
  }
}

export function usePagination({
  skip,
  take,
  totalItems,
}: {
  skip: number
  take: number
  totalItems: number
}) {
  const initialPagination: PaginationState = useMemo(() => {
    return {
      skip,
      take,
      current: 1,
      lastPage: 0,
    }
  }, [skip, take])

  const [state, dispatch] = useReducer(reducer, initialPagination)

  const lastPageCalculated: number = useMemo(() => {
    if (totalItems) {
      return Math.ceil(totalItems / take)
    }
    return 0
  }, [totalItems, take])

  function next() {
    if (state.current === state.lastPage) return
    setPage(state.current + 1)
  }

  function previous() {
    if (state.current === 1) return
    setPage(state.current - 1)
  }

  function reset() {
    dispatch({ type: "RESET_PAGINATION", payload: initialPagination })
    dispatch({ type: "UPDATE_LAST_PAGE", payload: lastPageCalculated })
  }

  function setPage(pageNumber: number) {
    if (pageNumber < 1 || pageNumber > lastPageCalculated) return
    dispatch({
      type: "SET_PAGE",
      payload: { skip: take * (pageNumber - 1), page: pageNumber },
    })
  }

  useEffect(() => {
    let lastPageNumber = 0
    if (totalItems) {
      lastPageNumber = Math.ceil(totalItems / take)
    }
    const payload: PaginationState = {
      ...state,
      lastPage: lastPageNumber,
    }
    if (state.current > lastPageNumber || !state.current) {
      payload.current = lastPageNumber
      payload.skip = take * (lastPageNumber < 2 ? 0 : lastPageNumber - 1)
    }
    dispatch({ type: "UPDATE_PAGINATION", payload })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalItems])

  return {
    pagination: {
      skip: state.skip,
      take: state.take,
    },
    current: state.current,
    lastPage: state.lastPage,
    next,
    reset,
    setPage,
    previous,
  }
}
