import {
  formatDate,
  timeStampToDate,
  subDays,
  startOfDay,
  endOfDay,
  addMonths,
} from "@cashbook/util-dates"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import { Link, useNavigate } from "react-router-dom"
import {
  Alert,
  Button,
  CancelIcon,
  ClockIcon,
  DataLoadingFallback,
  FormAmountField,
  FormDatePicker,
  FormField,
  FormTimePicker,
  GearIcon,
  Modal,
  ModalBody,
  ModalFooter,
  Heading,
  Text,
  Box,
  Inline,
  Stack,
  Time,
  TrashIcon,
  useOverlayTriggerState,
  checkFileSize,
  checkFileTypes,
  BillDetailIcon,
  Tooltip,
  PlusIcon,
  InputLabel,
  CancelFilledIcon,
  getButtonClassName,
  AttachmentIcon,
  readFileAsDataURL,
  PDFOutlineIcon,
  DocumentDownloadIcon,
  SpinnerIcon,
  BackupIcon,
  CBButton,
} from "@cashbook/web-components"
import classNames from "classnames"
import { Form, Formik, useField } from "formik"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import toast from "react-hot-toast"
import { SuspenseWithPerf, useUser } from "reactfire"
import { $PropertyType, Optional } from "utility-types"
import * as Validator from "yup"
import { Amount } from "../support/Intl"
import {
  addKeyboardShortcut,
  useKeyboardShortcuts,
} from "../support/KeyboardShortcuts"
import {
  useAddTransaction,
  useTransaction,
  TBook,
  LogTransactionDataSchema,
  TTransaction,
  checkIfMemberCan,
  BOOK_PERMISSIONS,
  PARTY_PREFERENCES,
  useBook,
  usePartyOrContact,
  EntryAttachmentInfo,
  useDeleteTransactions,
  TBookCustomField,
  TBookCustomFields,
} from "@cashbook/data-store/books"
import {
  removeSyncStoredItem,
  useSyncedStorageState,
  useUploadEntryAttachment,
} from "@cashbook/data-store/storage"
import {
  AskForPartyPhoneNumber,
  SelectCategory,
  SelectParty,
  SelectPaymentMode,
  TSelectFieldOption,
} from "../Books"
import {
  isPhoneNumberIndian,
  pluralize,
  RECORD_ENTRY_MIN_DATE,
} from "@cashbook/util-general"
import { getDeviceOS } from "@cashbook/device-info"
import {
  Thumbnail,
  convertFileSize,
  generateUUID,
  useDownloadAttachments,
} from "./Attachments"
import { Tag } from "../common"
import { AddNewCustomFieldInDialog } from "../Books/EntryFields"

type T_Entry_Type = "cash-in" | "cash-out"

type LogTransactionFormProps = {
  onSubmit: (data: LogTransactionDataSchema) => Promise<void>
  bookId: string
  onCancel?: () => void
  initialValues?: Optional<LogTransactionDataSchema>
  /**
   * Specify the type of transaction. This will also hide the type togglers.
   * If you just want to pass initial value for type, pass it in initialValues
   */
  type?: T_Entry_Type
  onTypeChange?: (type: T_Entry_Type) => void
  parties?: $PropertyType<TBook, "parties">
  categories?: $PropertyType<TBook, "categories">
  paymentModes?: $PropertyType<TBook, "paymentModes">
  partyDisabled?: boolean
  categoriesDisabled?: boolean
  paymentModesDisabled?: boolean
  partyPreferences?: PARTY_PREFERENCES
  disableAddMore?: boolean
  minDate?: Date
  maxDate?: Date
  canUpdateEntryFields: boolean
  customFields?: Record<string, string | undefined>
}

type LogTransactionProps = Omit<
  LogTransactionFormProps,
  "onSubmit" | "bookId" | "minDate" | "maxDate" | "canUpdateEntryFields"
> & {
  book: TBook
  onSuccess?: (data: LogTransactionDataSchema) => void
}

export function LogTransaction({
  book,
  onSuccess,
  ...props
}: LogTransactionProps) {
  const addTransaction = useAddTransaction(book)
  const { data: user } = useUser()
  const userId = user?.uid || ""

  const canAddFutureDatedEntries = checkIfMemberCan(
    book,
    userId,
    BOOK_PERMISSIONS.ADD_FUTURE_DATED_ENTRIES
  )

  const canAddBackDated = checkIfMemberCan(
    book,
    userId,
    BOOK_PERMISSIONS.ADD_BACK_DATED_ENTRIES
  )
  const canAddBackDatedTillYesterday = checkIfMemberCan(
    book,
    userId,
    BOOK_PERMISSIONS.ADD_BACK_DATED_ENTRIES_TILL_YESTERDAY
  )
  const canUpdateEntryFields = checkIfMemberCan(
    book,
    userId,
    BOOK_PERMISSIONS.UPDATE_ENTRY_FIELDS
  )
  return (
    <LogTransactionForm
      {...props}
      bookId={book.id}
      parties={book.parties}
      paymentModes={book.paymentModes}
      categories={book.categories}
      partyPreferences={book.partyPreferences}
      partyDisabled={book.preferences?.partyDisabled}
      categoriesDisabled={book.preferences?.categoriesDisabled}
      paymentModesDisabled={book.preferences?.paymentModesDisabled}
      minDate={
        canAddBackDated
          ? RECORD_ENTRY_MIN_DATE
          : canAddBackDatedTillYesterday
          ? subDays(startOfDay(new Date()), 1)
          : startOfDay(new Date())
      }
      canUpdateEntryFields={canUpdateEntryFields}
      onSubmit={async (data) => {
        addTransaction(data)
        onSuccess?.(data)
      }}
      maxDate={canAddFutureDatedEntries ? addMonths(new Date(), 1) : undefined}
    />
  )
}

export function getLabelForTransactionType(
  type: T_Entry_Type | undefined | null
) {
  return !type ? "" : type === "cash-in" ? "Cash In" : "Cash Out"
}

addKeyboardShortcut(
  "c+i",
  `Open "Add ${getLabelForTransactionType("cash-in")}" popup`
)
addKeyboardShortcut(
  "c+o",
  `Open "Add ${getLabelForTransactionType("cash-out")}" popup`
)

export function LogTransactionInDialog({
  onSuccess,
  onCancel,
  children,
  ...props
}: Omit<React.ComponentProps<typeof LogTransaction>, "onTypeChange"> & {
  children: (props: { add: (type: T_Entry_Type) => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const [transactionType, setTransactionType] = useState(props.type)
  useKeyboardShortcuts("c+i,c+o", (key) => {
    switch (key) {
      case "c+i":
        trackEvent(TrackingEvents.KEYBOARD_SHORTCUT_USED, {
          shortcut: "cash-in",
        })
        setTransactionType("cash-in")
        if (!state.isOpen) state.open()
        break
      case "c+o":
        trackEvent(TrackingEvents.KEYBOARD_SHORTCUT_USED, {
          shortcut: "cash-out",
        })
        setTransactionType("cash-out")
        if (!state.isOpen) state.open()
        break
    }
  })
  return (
    <>
      {children({
        add: (type) => {
          setTransactionType(type)
          state.open()
        },
      })}
      <Modal
        isOpen={state.isOpen}
        title={`Add ${getLabelForTransactionType(transactionType)} Entry`}
        placement="right"
        status={transactionType === "cash-in" ? "success" : "error"}
        onClose={state.close}
      >
        <SuspenseWithPerf
          fallback={<DataLoadingFallback label="Loading module.." />}
          traceId="loading_functions_module"
        >
          {!transactionType ? null : (
            <Box
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === "Esc" || e.key === "Escape") {
                  state.close()
                  return
                }
              }}
            >
              <LogTransaction
                {...props}
                type={transactionType}
                onTypeChange={(type) => setTransactionType(type)}
                onCancel={() => {
                  state.close()
                  onCancel?.()
                }}
                onSuccess={(data) => {
                  if (!data.usingSaveAndNext) {
                    state.close()
                  }
                  onSuccess?.(data)
                }}
              />
            </Box>
          )}
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

type EditTransactionProps = LogTransactionProps & {
  transactionId: string
  involvedUsers: Array<{ id: string; name: string }>
  customFields?: { [key: string]: string | undefined }
}

export function EditTransaction({
  book,
  transactionId,
  onSuccess,
  involvedUsers,
  customFields,
  ...props
}: EditTransactionProps) {
  const { transaction, updateTransaction } = useTransaction(book, transactionId)
  const { data: user } = useUser()
  const userId = user?.uid || ""

  const canAddFutureDatedEntries = checkIfMemberCan(
    book,
    userId,
    BOOK_PERMISSIONS.ADD_FUTURE_DATED_ENTRIES
  )

  const canAddBackDated = checkIfMemberCan(
    book,
    userId,
    BOOK_PERMISSIONS.ADD_BACK_DATED_ENTRIES
  )
  const canAddBackDatedTillYesterday = checkIfMemberCan(
    book,
    userId,
    BOOK_PERMISSIONS.ADD_BACK_DATED_ENTRIES_TILL_YESTERDAY
  )
  const canUpdateEntryFields = checkIfMemberCan(
    book,
    userId,
    BOOK_PERMISSIONS.UPDATE_ENTRY_FIELDS
  )

  const initialValues = useMemo(() => {
    return {
      amount: transaction.amount,
      remark: transaction.remark,
      type: transaction.type,
      date: timeStampToDate(transaction.date),
      thumbUrl: transaction.thumbUrl,
      imageUrl: transaction.imageUrl,
      attachments: transaction.attachments,
      partyId: transaction.partyId,
      paymentModeId: transaction.paymentModeId,
      categoryId: transaction.categoryId,
    }
  }, [transaction])

  return (
    <LogTransactionForm
      {...props}
      bookId={book.id}
      parties={book.parties}
      categories={book.categories}
      paymentModes={book.paymentModes}
      partyPreferences={book.partyPreferences}
      partyDisabled={book.preferences?.partyDisabled}
      categoriesDisabled={book.preferences?.categoriesDisabled}
      paymentModesDisabled={book.preferences?.paymentModesDisabled}
      initialValues={initialValues}
      customFields={customFields}
      minDate={
        canAddBackDated
          ? RECORD_ENTRY_MIN_DATE
          : canAddBackDatedTillYesterday
          ? subDays(startOfDay(new Date()), 1)
          : startOfDay(new Date())
      }
      maxDate={canAddFutureDatedEntries ? addMonths(new Date(), 1) : undefined}
      canUpdateEntryFields={canUpdateEntryFields}
      onSubmit={async (data) => {
        updateTransaction(data)
        onSuccess?.(data)
      }}
    />
  )
}

export function EditTransactionInDialog({
  onSuccess,
  onCancel,
  children,
  ...props
}: React.ComponentProps<typeof EditTransaction> & {
  children: (props: { edit: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({ edit: () => state.open() })}
      <Modal
        isOpen={state.isOpen}
        title="Edit Entry"
        isDismissable
        onClose={state.close}
        placement="right"
      >
        <SuspenseWithPerf
          traceId="loading_transaction_details_to_edit"
          fallback={<DataLoadingFallback label="Loading entry details.." />}
        >
          <EditTransaction
            {...props}
            onCancel={() => {
              state.close()
              onCancel?.()
            }}
            onSuccess={(data) => {
              state.close()
              onSuccess?.(data)
            }}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

export function DeleteTransactions({
  book,
  transactions,
  children,
  onSuccess,
}: {
  book: TBook
  transactions?: string[]
  children: (props: {
    onDelete: (transaction?: TTransaction) => void
  }) => React.ReactNode
  onSuccess?: () => void
}) {
  const { deleteTransaction, deleteTransactions } = useDeleteTransactions(book)
  const state = useOverlayTriggerState({})
  const transactionRef = useRef<TTransaction | undefined>(undefined)
  function onClose() {
    transactionRef.current = undefined
    state.close()
  }

  const numOfEntries = transactions?.length

  return (
    <>
      {children({
        onDelete: (t) => {
          transactionRef.current = t
          state.open()
        },
      })}
      <Modal
        isOpen={state.isOpen}
        title={`Delete ${numOfEntries || ""} ${pluralize(
          "Entry",
          numOfEntries || 1
        )}`}
        isDismissable
        onClose={onClose}
      >
        <Formik
          initialValues={{ confirmed: true }}
          onSubmit={async () => {
            if (transactionRef.current?.id) {
              await deleteTransaction(transactionRef.current)
              onSuccess?.()
            } else if (numOfEntries) {
              await deleteTransactions(transactions)
              onSuccess?.()
            } else {
              return
            }
            toast.success(`${numOfEntries || 1} ${pluralize(
              "entry",
              numOfEntries || 1
            )} deleted
            successfully`)
            onClose()
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <Stack as="section">
                  <Stack>
                    <Alert status="warning">
                      Once deleted, {numOfEntries ? "these" : "this"}{" "}
                      {pluralize("entry", numOfEntries || 1)}{" "}
                      <b>cannot be restored.</b>
                      <br />
                      {numOfEntries
                        ? null
                        : "Are you sure you want to Delete ?"}
                    </Alert>
                    {numOfEntries ? (
                      <Stack gap="4">
                        <Text fontSize="b3">Are you sure?</Text>
                        <Text fontSize="b3">
                          You want to delete {numOfEntries} entries from{" "}
                          <b>‘{book.name}’</b>
                        </Text>
                      </Stack>
                    ) : null}
                  </Stack>
                  {transactionRef.current?.id ? (
                    <Stack gap="2">
                      <Heading as="h4" fontWeight="medium">
                        Review Details
                      </Heading>
                      <TransactionDetailsBox
                        customFields={book?.customFields}
                        transaction={transactionRef.current}
                      />
                    </Stack>
                  ) : null}
                </Stack>
              </ModalBody>
              <ModalFooter>
                <CBButton
                  disabled={isSubmitting}
                  iconPlacement="left"
                  size="lg"
                  level="primary"
                  onClick={onClose}
                >
                  <CancelIcon />
                  Cancel
                </CBButton>
                <CBButton
                  status="danger"
                  type="submit"
                  size="lg"
                  level="secondary"
                  iconPlacement="left"
                  loading={isSubmitting}
                >
                  <TrashIcon />
                  {!isSubmitting ? "Yes, Delete" : "Deleting..."}
                </CBButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

function TransactionDetailsBox({
  transaction,
  customFields,
}: {
  transaction: TTransaction
  customFields?: TBookCustomFields
}) {
  const fields: { [key: string]: string } = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const t: any = transaction
    const fieldObj: { [key: string]: string } = {}
    Object.keys(customFields || {})
      .filter((key) => t?.[key] && customFields?.[key])
      .sort(
        (a, b) =>
          (customFields?.[a].order || 0) - (customFields?.[b].order || 0)
      )
      .forEach((key) => {
        fieldObj[`${customFields?.[key].name}`] = t?.[key]
      })
    return fieldObj
  }, [customFields, transaction])

  return (
    <Stack gap="4" borderWidth="1" rounded="md" padding="4">
      <Inline flexWrap="wrap" gap="4">
        <Stack gap="1">
          <Heading as="h5" color="gray500" fontSize="sm">
            Type
          </Heading>
          <Text>{getLabelForTransactionType(transaction.type)}</Text>
        </Stack>
        <Stack gap="1">
          <Heading as="h5" color="gray500" fontSize="sm">
            Amount
          </Heading>
          <Amount amount={transaction.amount} />
        </Stack>
        {Object.keys(fields).map((key) => {
          return (
            <Stack gap="1">
              <Heading as="h5" color="gray500" fontSize="sm">
                {key}
              </Heading>
              <Text>{fields[key]}</Text>
            </Stack>
          )
        })}
        <Stack gap="1">
          <Heading as="h5" color="gray500" fontSize="sm">
            Date
          </Heading>
          <Time timeStamp={transaction.date} />
        </Stack>
      </Inline>
      {transaction.remark ? (
        <Stack gap="1">
          <Heading as="h5" color="gray500" fontSize="sm">
            Remark
          </Heading>
          <Text>{transaction.remark}</Text>
        </Stack>
      ) : null}
    </Stack>
  )
}

const MAX_AMOUNT = 999999999
const deviceOs = getDeviceOS()
type MULTIPLE_FILE_TYPE = {
  fileName: string
  id: string
  mimeType: string
  thumbUrl?: string | null
  url: string
  file?: File
}

const randomIdForPrevAttachments = generateUUID()

const validateBillFile = checkFileTypes([
  "image/png",
  "image/jpeg",
  "image/jpeg",
  "application/pdf",
])
const checkFileSizes = checkFileSize(10000)

function LogTransactionForm({
  onSubmit,
  initialValues: initialValuesProp,
  type,
  onTypeChange,
  parties,
  categories,
  paymentModes,
  partyDisabled,
  categoriesDisabled,
  paymentModesDisabled,
  bookId,
  disableAddMore,
  minDate,
  maxDate,
  partyPreferences,
  canUpdateEntryFields,
  customFields: transactionCustoms,
}: LogTransactionFormProps) {
  const initialValues: Omit<
    LogTransactionDataSchema,
    "usingCalculator" | "bookId" | "partyId" | "categoryId" | "paymentModeId"
  > & {
    bill_file?: { file: Blob; fileType: string; fileName: string }
    amountRaw: string
    attachments?: { [id: string]: EntryAttachmentInfo }
    // This is to rerender the form element when adding adding entries one after another
    submitCount: number
    party: TSelectFieldOption | null
    paymentMode: TSelectFieldOption | null
    category: TSelectFieldOption | null
  } = useMemo(() => {
    const paymentMode =
      paymentModes?.find(
        (field) => field.uuid === initialValuesProp?.paymentModeId
      ) || (paymentModes?.length ? paymentModes[0] : null)
    const party =
      parties?.find((field) => field.uuid === initialValuesProp?.partyId) ||
      null
    const category =
      categories?.find(
        (field) => field.uuid === initialValuesProp?.categoryId
      ) || null
    return {
      remark: initialValuesProp?.remark || "",
      amount: (initialValuesProp?.amount || "") as number,
      type: initialValuesProp?.type || type || "cash-out",
      date: initialValuesProp?.date || new Date(),
      imageUrl: initialValuesProp?.imageUrl || null,
      thumbUrl: initialValuesProp?.thumbUrl || null,
      attachments: Object.keys(initialValuesProp?.attachments || {}).length
        ? initialValuesProp?.attachments
        : initialValuesProp?.imageUrl && initialValuesProp?.thumbUrl
        ? {
            [randomIdForPrevAttachments]: {
              id: randomIdForPrevAttachments,
              url: initialValuesProp?.imageUrl || "",
              fileName: `attachment_${randomIdForPrevAttachments}`,
              mimeType: "image/jpeg",
              thumbUrl: initialValuesProp?.thumbUrl || "",
            },
          }
        : {},
      amountRaw: (initialValuesProp?.amount || "") as string,
      submitCount: 0,
      paymentMode: paymentMode
        ? { id: paymentMode.uuid, label: paymentMode.name, ...paymentMode }
        : null,
      paymentModeFromSuggestions: undefined,
      party: party ? { id: party.uuid, label: party.name, ...party } : null,
      category: category
        ? { id: category.uuid, label: category.name, ...category }
        : null,
      categoryFromSuggestions: undefined,
    }
  }, [initialValuesProp, type, parties, categories, paymentModes])

  const { uploadAttachment } = useUploadEntryAttachment()
  const { book, updatePartyPreferences } = useBook(bookId)
  const [newFeaturesIntroduced, setNewFeaturesIntroduced] =
    useSyncedStorageState<{ [key: string]: string[] }>("newFeatures", {})

  const [dismissedBanners, setDismissedBanners] = useSyncedStorageState<
    string[]
  >("dismissedBanners", [])

  const isCustomFieldBannerDismissed: boolean = useMemo(() => {
    return dismissedBanners.includes("add-custom-fields")
  }, [dismissedBanners])

  const { businessId, customFields } = book

  const fields: TBookCustomField[] = useMemo(() => {
    return (
      Object.keys(customFields || {})
        .map((key) => {
          return {
            name: customFields?.[key].name,
            order: customFields?.[key].order,
            required: customFields?.[key].required,
            type: customFields?.[key].type,
            fieldName: key,
          } as TBookCustomField
        })
        .sort((a, b) => (a.order || 0) - (b.order || 0)) || []
    )
  }, [customFields])

  const logTransactionDataValidationSchema = useMemo(() => {
    let dateValidation = Validator.date().required(
      "Please select the transaction date"
    )
    if (minDate) {
      dateValidation = dateValidation.min(
        minDate,
        `Please select a date on or after ${formatDate(
          minDate,
          "dd MMMM, yyyy"
        )}`
      )
    }
    return Validator.object().shape({
      remark: Validator.string()
        .nullable()
        .max(150, "Please use 150 characters or less."),
      amount: Validator.number()
        .nullable()
        .positive("The amount should be a positive number")
        .max(
          MAX_AMOUNT,
          `Maximum amount limit (${MAX_AMOUNT}) reached. Please create another entry for remaining
      amount.`
        ),
      type: Validator.string()
        .equals(["cash-in", "cash-out"])
        .required("Please select the type of transaction"),
      date: dateValidation,
      party: Validator.mixed().nullable(),
      category: Validator.mixed().nullable(),
      paymentMode: Validator.mixed().nullable(),
    })
  }, [minDate])
  const [isEditingTime, setEditTime] = useState(false)
  const [shouldAskForPartyPhoneNumber, setShouldAskForPartyPhoneNumber] =
    useState(false)
  useEffect(() => {
    if (isEditingTime) {
      trackEvent(TrackingEvents.TIME_PICKER_CLICKED)
    }
  }, [isEditingTime])
  const saveAndContinueRef = useRef<boolean>(true)
  const isEditing = !type
  const { data: user } = useUser()
  const activeField = useRef<string | undefined>(undefined)

  const showNewBannerForCustomFields: string | undefined = useMemo(() => {
    return !newFeaturesIntroduced[user?.uid || ""]?.includes("custom-fields")
      ? "custom-fields"
      : undefined
  }, [newFeaturesIntroduced, user?.uid])

  //Moving categories and payment modes into view
  const categoryAndPaymentModesRef = useRef<HTMLInputElement>(null)
  const scrollCategoryAndPaymentModesRef = () => {
    setTimeout(() => {
      if (categoryAndPaymentModesRef.current) {
        categoryAndPaymentModesRef.current.scrollIntoView({
          behavior: "smooth",
        })
      }
    }, 100)
  }

  const ifPartyWillReceiveSMS = useCallback(
    (partyId: string) => {
      return partyPreferences &&
        partyPreferences[partyId] &&
        partyPreferences[partyId].sendSMS
        ? true
        : false
    },
    [partyPreferences]
  )

  const navigate = useNavigate()
  const { partyOrContact, partiesOrContacts } = usePartyOrContact()

  return (
    <Formik
      initialValues={{ ...transactionCustoms, ...initialValues }}
      validationSchema={logTransactionDataValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={async (
        {
          attachments,
          bill_file,
          amountRaw,
          submitCount,
          party,
          category,
          paymentMode,
          ...values
        },
        actions
      ) => {
        if (bill_file) {
          try {
            trackEvent(TrackingEvents.BILL_IMAGE_UPLOAD_STARTED, {
              mimeType: bill_file.fileType,
              fileSizeInMBs: `${convertFileSize(
                bill_file.file.size,
                "B",
                "MB"
              ).toFixed(3)}`,
            })
            const imageData = await uploadAttachment({
              file: bill_file.file,
              name: bill_file.fileName,
              mimeType: bill_file.fileType,
            })
            actions.setFieldValue("attachments", {
              ...attachments,
              [imageData.id]: {
                ...imageData,
              },
            })
            trackEvent(TrackingEvents.BILL_IMAGE_UPLOAD_FINISHED, {
              mimeType: bill_file.fileType,
              fileSizeInMBs: `${convertFileSize(
                bill_file.file.size,
                "B",
                "MB"
              ).toFixed(3)}`,
            })
            actions.setFieldValue("bill_file", undefined)
          } catch {
            actions.setFieldValue("bill_file", undefined)
            toast.error("Error while uploading attachment. Please try again!")
            return
          }
          return
        }
        if (
          !values.amount ||
          values.amount === undefined ||
          values.amount === null
        )
          return actions.setFieldError(
            "amount",
            "Please enter the amount of transaction"
          )
        removeSyncStoredItem("isNewUser")
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const v: any = values
        const errs: { [key: string]: string } = {}
        fields
          .filter((field) => field.required)
          .forEach((field) => {
            if (field.fieldName && !v?.[field.fieldName]) {
              errs[field.fieldName] = `${field.name} is a required field!`
            }
          })
        if (Object.keys(errs).length) {
          actions.setErrors(errs)
          actions.setTouched(errs)
          return
        }
        await onSubmit({
          attachments: attachments,
          usingCalculator: Boolean(values.amount) && isNaN(Number(amountRaw)),
          ...values,
          partyId: party?.uuid || null,
          categoryId: category?.uuid || null,
          paymentModeId: paymentMode?.uuid || null,
          usingSaveAndNext: saveAndContinueRef.current,
          sharable:
            party?.uuid && ifPartyWillReceiveSMS(party?.uuid) ? true : false,
        })
        if (isEditing) {
          saveAndContinueRef.current = true
          toast.success(`Entry Updated`)
        } else if (!saveAndContinueRef.current) {
          // reset the default
          saveAndContinueRef.current = true
          toast.success(
            `Entry Saved ${
              party?.uuid && ifPartyWillReceiveSMS(party?.uuid)
                ? `& SMS sent to ${party.name}`
                : ""
            }`
          )
        } else {
          toast.success("Saved. Continue adding more entries.")
          // reset the form and continue adding new entries
          actions.resetForm()
          const date = new Date(values.date)

          const fieldValues: { [key: string]: string } = {}
          fields.forEach((field) => {
            if (field.fieldName) {
              fieldValues[field.fieldName] = ""
            }
          })
          // make sure it is greater than the current one
          date.setSeconds(date.getSeconds() + 1)
          actions.setValues({
            ...values,
            date,
            attachments: {},
            submitCount: submitCount + 1,
            remark: "",
            amountRaw: "",
            amount: "" as unknown as number,
            imageUrl: null,
            thumbUrl: null,
            bill_file: undefined,
            party: null,
            category: null,
            paymentMode,
            ...fieldValues,
          })
        }
      }}
    >
      {({ isSubmitting, setFieldValue, values, submitForm }) => {
        return (
          <Form
            noValidate
            encType="multiple/form-data"
            key={values.submitCount}
          >
            {values.submitCount >= 2 && values.submitCount <= 4 ? (
              <ToolTipsForShortCuts
                tooltipFor="i/o"
                type={values.type === "cash-in" ? "cash-out" : "cash-in"}
              />
            ) : values.submitCount >= 5 && values.submitCount <= 7 ? (
              <ToolTipsForShortCuts
                tooltipFor={
                  activeField.current === "amount" ? "next" : "previous"
                }
              />
            ) : null}
            <ModalBody className="relative">
              <Box role="group" aria-labelledby="Transaction Type">
                <SelectEntryType
                  name="type"
                  onChange={(type) => {
                    onTypeChange?.(type)
                  }}
                />
              </Box>
              <Inline justifyContent="between">
                <Box style={{ maxWidth: "200px" }}>
                  <FormDatePicker
                    name="date"
                    label="Date"
                    required
                    max={maxDate ? maxDate : endOfDay(new Date())}
                    min={minDate}
                    onOpen={() => {
                      trackEvent(TrackingEvents.DATE_PICKER_CLICKED)
                    }}
                  />
                </Box>
                <Box>
                  {isEditingTime ? (
                    <FormTimePicker
                      name="date"
                      label="Time"
                      id="time"
                      required
                    />
                  ) : (
                    <Stack gap="1">
                      <Text as="label" fontWeight="medium" fontSize="sm">
                        Time
                      </Text>
                      <Inline
                        as="button"
                        title="Click to edit Time"
                        type="button"
                        gap="2"
                        paddingX="4"
                        alignItems="center"
                        borderWidth="1"
                        rounded="md"
                        className="h-[40px]"
                        onClick={() => setEditTime(true)}
                      >
                        <ClockIcon />
                        <Text as="span" fontWeight="medium">
                          {formatDate(values.date, "hh:mm a")}
                        </Text>
                      </Inline>
                    </Stack>
                  )}
                </Box>
              </Inline>
              <FormAmountField
                name="amount"
                rawName="amountRaw"
                label="Amount"
                autoComplete="off"
                required
                placeholder="eg. 890 or 100 + 200*3"
                autoFocus
                onFocus={() => {
                  activeField.current = "amount"
                }}
              />
              {!partyDisabled ? (
                <Stack>
                  <Box className="grid sm:grid-cols-2 sm:gap-6">
                    <FormField
                      name="party"
                      label={`${partyOrContact} Name ${
                        partyOrContact === "Party" ? "(Contact)" : ""
                      }`}
                      actionLabel={
                        <Button
                          inline
                          onClick={() => {
                            navigate("../settings/fields/parties")
                          }}
                          className="px-1 text-gray-500 hover:text-blue-900"
                        >
                          <GearIcon size="4" title="Configure Parties" />
                        </Button>
                      }
                      renderInput={({ field, form }) => {
                        return (
                          <SelectParty
                            entryType={values.type}
                            bookId={bookId}
                            value={field.value}
                            footer={
                              canUpdateEntryFields ? (
                                <Inline
                                  gap="3"
                                  marginX="3"
                                  rounded="md"
                                  paddingX="3"
                                  paddingY="2"
                                  borderWidth="1"
                                  marginBottom="4"
                                  alignItems="center"
                                  as={Link}
                                  to={`/businesses/${businessId}/cashbooks/${bookId}/import-parties`}
                                  borderColor="borderOutline"
                                  backgroundColor="surfaceSuccessLowest"
                                >
                                  <Box>
                                    <BackupIcon color="iconCashIn" />
                                  </Box>
                                  <Text fontSize="b3">
                                    Import Bulk{" "}
                                    {partiesOrContacts.toLowerCase()} from CSV
                                  </Text>
                                </Inline>
                              ) : null
                            }
                            focusInputOnKey="Enter"
                            onChange={(value) => {
                              form.setFieldValue("party", value)
                            }}
                          />
                        )
                      }}
                    />
                  </Box>
                </Stack>
              ) : null}
              {!isEditing &&
              !partyDisabled &&
              values.party &&
              (values.party.phoneNumber
                ? isPhoneNumberIndian(values.party?.phoneNumber)
                : true) &&
              (canUpdateEntryFields || values.party.phoneNumber) ? (
                <Stack borderWidth="1" rounded="md" className="mb-6">
                  <Inline
                    padding="2"
                    rounded="md"
                    bgColor={
                      ifPartyWillReceiveSMS(values.party.uuid)
                        ? "white"
                        : "gray100"
                    }
                    alignItems="center"
                    justifyContent="between"
                    paddingY="4"
                  >
                    <Inline gap="2" alignItems="center" justifyContent="center">
                      <BillDetailIcon color="green500" />
                      <Text>Send bill to {values.party.name} via free SMS</Text>
                    </Inline>
                    <FormField
                      noMargin
                      name="partySms"
                      type="checkbox"
                      id={`partySms_${values.party.uuid}`}
                      style={{
                        borderWidth: 2,
                        borderColor: ifPartyWillReceiveSMS(values.party.uuid)
                          ? undefined
                          : "#757575",
                      }}
                      onChange={() => {
                        if (values.party) {
                          if (
                            !ifPartyWillReceiveSMS(values.party.uuid) &&
                            !values.party.phoneNumber
                          ) {
                            return setShouldAskForPartyPhoneNumber(true)
                          }
                          updatePartyPreferences({
                            [values.party.uuid]: {
                              sendSMS: ifPartyWillReceiveSMS(values.party.uuid)
                                ? false
                                : true,
                              sendWhatsApp: true,
                            },
                          })
                        }
                      }}
                      checked={ifPartyWillReceiveSMS(values.party.uuid)}
                      className="mr-1"
                    />
                  </Inline>
                  {ifPartyWillReceiveSMS(values.party.uuid) && (
                    <Stack
                      paddingX="3"
                      paddingY="4"
                      bgColor="gray100"
                      roundedBottom="md"
                    >
                      <Text>
                        Dear {values.party?.name}, your bill is ready! Click on
                        the link below to view: cash.pe/t/P4Kw9FIwL?s=pr&m=w -{" "}
                        {`${user?.displayName}(${user?.phoneNumber})`}
                      </Text>
                    </Stack>
                  )}
                </Stack>
              ) : null}
              <FormField
                name="remark"
                type="text"
                label="Remarks"
                onFocus={() => {
                  activeField.current = "remark"
                }}
                placeholder="e.g. Enter Details (Name, Bill No, Item Name, Quantity etc)"
              />
              {!paymentModesDisabled || !categoriesDisabled ? (
                <Box
                  className="grid sm:grid-cols-2 sm:gap-6"
                  ref={categoryAndPaymentModesRef}
                  onClick={scrollCategoryAndPaymentModesRef}
                >
                  {!categoriesDisabled &&
                  (categories?.length || canUpdateEntryFields) ? (
                    <FormField
                      name="category"
                      label="Category"
                      actionLabel={
                        <Button
                          inline
                          onClick={() => {
                            navigate("../settings/fields/categories")
                          }}
                          className="px-1 text-gray-500 hover:text-blue-900"
                        >
                          <GearIcon size="4" title="Configure Categories" />
                        </Button>
                      }
                      renderInput={({ field, form }) => (
                        <SelectCategory
                          entryType={values.type}
                          bookId={bookId}
                          value={field.value}
                          focusInputOnKey="Enter"
                          onChange={(value, fromSuggestions) => {
                            form.setFieldValue("category", value)
                            form.setFieldValue(
                              "categoryFromSuggestions",
                              fromSuggestions
                            )
                          }}
                        />
                      )}
                    />
                  ) : null}
                  {!paymentModesDisabled &&
                  (paymentModes?.length || canUpdateEntryFields) ? (
                    <FormField
                      name="paymentMode"
                      label="Payment Mode"
                      placeholder="e.g. Cash, Online"
                      actionLabel={
                        <Button
                          inline
                          onClick={() => {
                            navigate(`../settings/fields/payment-modes`)
                          }}
                          className="px-1 text-gray-500 hover:text-blue-900"
                        >
                          <GearIcon size="4" title="Configure Payment Modes" />
                        </Button>
                      }
                      renderInput={({ field, form }) => (
                        <SelectPaymentMode
                          entryType={values.type}
                          bookId={bookId}
                          value={field.value}
                          focusInputOnKey="Enter"
                          onChange={(value, fromSuggestions) => {
                            form.setFieldValue("paymentMode", value)
                            form.setFieldValue(
                              "paymentModeFromSuggestions",
                              fromSuggestions
                            )
                          }}
                        />
                      )}
                    />
                  ) : null}
                </Box>
              ) : null}
              <FormMultipleFilesField
                name="bill_file"
                id="bill_file"
                defaultValues={values.attachments}
                loading={isSubmitting}
                onSelectionEnd={submitForm}
                onRemove={(imageId: string) => {
                  if (values.imageUrl && values.thumbUrl) {
                    setFieldValue("imageUrl", null)
                    setFieldValue("thumbUrl", null)
                  }
                  const deletedAttachments = {
                    ...values.attachments,
                  }
                  delete deletedAttachments?.[imageId]
                  setFieldValue("attachments", deletedAttachments)
                }}
              />
              {fields.length ? (
                fields.map((field) => {
                  return field.fieldName ? (
                    <FormField
                      name={field.fieldName}
                      type="text"
                      label={field.name}
                      actionLabel={
                        <Button
                          inline
                          onClick={() => {
                            navigate("../settings/fields/custom-fields")
                          }}
                          className="px-1 text-gray-500 hover:text-blue-900"
                        >
                          <GearIcon size="4" title="Configure Field" />
                        </Button>
                      }
                      required={field.required}
                      key={field.fieldName}
                      placeholder={field.name}
                    />
                  ) : null
                })
              ) : !isCustomFieldBannerDismissed ? (
                <Box position="relative">
                  <Alert status="info" removeIcon>
                    <Inline
                      size="5"
                      as="button"
                      rounded="full"
                      borderWidth="1"
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor="white"
                      borderColor="borderOutline"
                      className="absolute -top-2 -right-2"
                      onClick={() => {
                        setDismissedBanners([
                          ...dismissedBanners,
                          "add-custom-fields",
                        ])
                      }}
                    >
                      <CancelIcon size="3" />
                    </Inline>
                    <Inline
                      gap="3"
                      alignItems="center"
                      justifyContent="between"
                    >
                      <Inline gap="2">
                        <Text
                          fontSize="b4"
                          fontWeight="semibold"
                          color="textAlt2"
                        >
                          Add more fields
                        </Text>
                        {showNewBannerForCustomFields ? <Tag /> : null}
                      </Inline>
                      <AddNewCustomFieldInDialog book={book}>
                        {({ add }) => (
                          <CBButton
                            level="primary"
                            iconPlacement="left"
                            onClick={() => {
                              add()
                              if (showNewBannerForCustomFields && user?.uid) {
                                setNewFeaturesIntroduced({
                                  ...newFeaturesIntroduced,
                                  [`${user.uid}`]: [
                                    ...(newFeaturesIntroduced[user.uid] ?? []),
                                    "custom-fields",
                                    "fields",
                                  ],
                                })
                              }
                            }}
                          >
                            Configure
                          </CBButton>
                        )}
                      </AddNewCustomFieldInDialog>
                    </Inline>
                  </Alert>
                </Box>
              ) : null}
            </ModalBody>
            <ModalFooter
              actionsLayout="row"
              className="sticky bottom-0 z-10 bg-white"
            >
              {!isEditing && !disableAddMore ? (
                <Tooltip
                  event="onHover"
                  position="top"
                  content={
                    <Inline gap="4" alignItems="center" paddingBottom="1">
                      <Inline
                        className="bg-[#3A4060]"
                        rounded="md"
                        paddingY="1"
                        padding="2"
                        gap="1"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box>
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#EnterIcon)">
                              <path
                                d="M12.6667 5.16667V7.83333H3.88671L6.27337 5.44L5.33337 4.5L1.33337 8.5L5.33337 12.5L6.27337 11.56L3.88671 9.16667H14V5.16667H12.6667Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="EnterIcon">
                                <rect
                                  y="0.5"
                                  width="16"
                                  height="16"
                                  rx="4"
                                  fill="white"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </Box>
                        <Text fontSize="c2">
                          {deviceOs === "ios" ? "Return" : "Enter"}
                        </Text>
                      </Inline>
                      <Stack>
                        <Text>Save & Move</Text>
                        <Text>to next entry</Text>
                      </Stack>
                    </Inline>
                  }
                >
                  <Button disabled={isSubmitting} type="submit" size="lg">
                    {isSubmitting && saveAndContinueRef.current
                      ? "Saving..."
                      : "Save & Add New"}
                  </Button>
                </Tooltip>
              ) : null}
              <Button
                disabled={isSubmitting}
                type={isEditing || disableAddMore ? "submit" : "button"}
                size="lg"
                onClick={() => {
                  if (isEditing) {
                    // type attribute will handle the submission
                    return
                  }
                  saveAndContinueRef.current = false
                  submitForm()
                }}
              >
                {isSubmitting && !saveAndContinueRef.current
                  ? "Saving..."
                  : "Save"}
              </Button>
            </ModalFooter>
            {shouldAskForPartyPhoneNumber && values.party ? (
              <AskForPartyPhoneNumber
                bookId={bookId}
                partyId={values.party.uuid}
                onClose={() => {
                  setShouldAskForPartyPhoneNumber(false)
                }}
                onSuccess={(phoneNumber) =>
                  setFieldValue("party", { ...values.party, phoneNumber })
                }
                partyName={values.party.name}
              />
            ) : null}
          </Form>
        )
      }}
    </Formik>
  )
}

function SelectEntryType({
  name,
  onChange,
  readOnly,
}: {
  name: string
  onChange?: (type: T_Entry_Type) => void
  readOnly?: boolean
}) {
  const [
    { value, ...props },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _,
    { setValue },
  ] = useField<T_Entry_Type>(name)
  useKeyboardShortcuts("c+i,c+o", (key) => {
    if (readOnly) return
    switch (key) {
      case "c+i":
        setValue("cash-in")
        onChange?.("cash-in")
        break
      case "c+o":
        setValue("cash-out")
        onChange?.("cash-out")
        break
    }
  })
  return (
    <Inline gap="4" marginBottom="6">
      <Box
        id={`${name}_cash_in`}
        className={classNames(
          "px-4 py-2 inline-block cursor-pointer focus-within:ring-2 focus-within:ring-blue-500 border border-green-100 rounded-full",
          {
            "bg-green-900 text-white": value === "cash-in",
          }
        )}
        {...props}
        onClick={() => {
          setValue("cash-in")
          onChange?.("cash-in")
        }}
      >
        <Text fontSize={value === "cash-in" ? "c1" : "c2"}>
          {getLabelForTransactionType("cash-in")}
        </Text>
      </Box>
      <Box
        id={`${name}_cash_out`}
        className={classNames(
          "px-4 py-2 inline-block cursor-pointer focus-within:ring-2 focus-within:ring-blue-500 border border-green-100 rounded-full",
          {
            "bg-red-900 text-white": value === "cash-out",
          }
        )}
        {...props}
        onClick={() => {
          setValue("cash-out")
          onChange?.("cash-out")
        }}
      >
        <Text fontSize={value === "cash-out" ? "c1" : "c2"}>
          {getLabelForTransactionType("cash-out")}
        </Text>
      </Box>
    </Inline>
  )
}

function ToolTipsForShortCuts({
  type,
  tooltipFor,
}: {
  type?: "cash-in" | "cash-out"
  tooltipFor: "i/o" | "next" | "previous"
}) {
  return (
    <Inline
      gap="3"
      paddingY="3"
      paddingX="6"
      alignItems="center"
      backgroundColor="surfaceAlt2Lowest"
    >
      {tooltipFor === "i/o" ? (
        <Inline alignItems="center" gap="2">
          <Inline
            width="6"
            height="6"
            rounded="md"
            borderWidth="1"
            alignItems="center"
            justifyContent="center"
            borderColor="borderAlt2"
          >
            <Text fontSize="c2" color="textAlt2">
              C
            </Text>
          </Inline>
          <Box>
            <PlusIcon color="iconAlt2" size="4" />
          </Box>
          <Inline
            width="6"
            height="6"
            rounded="md"
            borderWidth="1"
            alignItems="center"
            justifyContent="center"
            borderColor="borderAlt2"
          >
            <Text fontSize="c2" color="textAlt2">
              {type === "cash-in" ? "I" : "O"}
            </Text>
          </Inline>
        </Inline>
      ) : tooltipFor === "next" ? (
        <Inline
          rounded="md"
          borderWidth="1"
          paddingY="1"
          paddingX="2"
          alignItems="center"
          justifyContent="center"
          borderColor="borderAlt2"
        >
          <Text fontSize="c2" color="textAlt2">
            Tab
          </Text>
        </Inline>
      ) : tooltipFor === "previous" ? (
        <Inline alignItems="center" gap="2">
          <Inline
            paddingY="1"
            paddingX="2"
            rounded="md"
            borderWidth="1"
            alignItems="center"
            justifyContent="center"
            borderColor="borderAlt2"
          >
            <Text fontSize="c2" color="textAlt2">
              Shift
            </Text>
          </Inline>
          <Box>
            <PlusIcon color="iconAlt2" size="4" />
          </Box>
          <Inline
            paddingY="1"
            paddingX="2"
            rounded="md"
            borderWidth="1"
            alignItems="center"
            justifyContent="center"
            borderColor="borderAlt2"
          >
            <Text fontSize="c2" color="textAlt2">
              Tab
            </Text>
          </Inline>
        </Inline>
      ) : null}

      <Text fontSize="c2" color="textAlt2">
        {tooltipFor === "next"
          ? "Go to next input field or button"
          : tooltipFor === "previous"
          ? "Move to previous input field"
          : `Switch to cash ${type === "cash-in" ? "in" : "out"} entry type`}
      </Text>
    </Inline>
  )
}

function FormMultipleFilesField({
  loading,
  maxFiles = 4,
  defaultValues,
  labelButtonProps,
  onRemove,
  onSelectionEnd,
  ...props
}: React.ComponentProps<typeof FormField> & {
  maxFiles?: number
  loading?: boolean
  onRemove?: (imageId: string) => void
  // Callback to handle the first image selection
  onSelectionStart?: () => void
  onPreview?: () => void
  onSelectionEnd?: () => void
  defaultValues?: { [id: string]: EntryAttachmentInfo }
  labelButtonProps?: React.ComponentProps<typeof Button>
}) {
  const id = props.id || props.name
  const [fileValue, setFileValue] = useState<string>("")
  const [uploading, setUploading] = useState<MULTIPLE_FILE_TYPE | undefined>(
    undefined
  )
  const [isDeleting, setIsDeleting] = useState<
    { from: "preview" | "delete" } | undefined
  >(undefined)
  const [defaultFileOnPreview, setDefaultFileOnPreview] = useState<
    MULTIPLE_FILE_TYPE | undefined
  >(undefined)

  const entryAttachments = useMemo(() => {
    return defaultValues ? Object.values(defaultValues) : []
  }, [defaultValues])

  const sameAttachment = useOverlayTriggerState({})
  const imagePreviewState = useOverlayTriggerState({})
  const {
    downloading,
    downloadingAll,
    downloadAttachment,
    downloadAllAttachments,
  } = useDownloadAttachments()

  function openImagePreview(image: MULTIPLE_FILE_TYPE) {
    setDefaultFileOnPreview(image)
    imagePreviewState.open()
  }

  function onDeleteIconClick(image?: MULTIPLE_FILE_TYPE) {
    if (image) {
      setDefaultFileOnPreview(image)
    }
    imagePreviewState.open()
  }

  function onCloseModal() {
    setIsDeleting(undefined)
    setUploading(undefined)
    setDefaultFileOnPreview(undefined)
    imagePreviewState.close()
  }

  async function download(type: "current" | "all") {
    if (type === "all") {
      await downloadAllAttachments(
        entryAttachments.map((attachmentItem) => {
          return {
            url: attachmentItem.url,
            fileName: attachmentItem.fileName,
          }
        })
      )
    } else {
      if (defaultFileOnPreview) {
        const currentAttachment = {
          url: defaultFileOnPreview.url,
          fileName: defaultFileOnPreview.fileName,
        }
        await downloadAttachment(currentAttachment)
      }
    }
  }

  return (
    <FormField
      {...props}
      renderInput={({ form, field }) => (
        <Box>
          <input
            accept=".pdf, image/jpeg, image/jpg, image/png"
            {...props}
            name={id}
            type="file"
            id={id}
            value={fileValue}
            className="opacity-0 absolute top-0 left-0 w-1 h-2"
            onBlur={() => {
              form.setFieldTouched(field.name, true)
            }}
            disabled={loading}
            onChange={({ currentTarget: { value, files } }) => {
              setFileValue(value.toString())
              if (files && files.length) {
                if ((entryAttachments?.length || 0) > maxFiles) {
                  return form.setFieldError(
                    id,
                    `You cannot select more than ${maxFiles} files.`
                  )
                }
                if (!validateBillFile(files[0])) {
                  return form.setFieldError(
                    id,
                    "Only files (png/jpeg/jpg/pdf) are supported."
                  )
                }
                if (!checkFileSizes(files[0])) {
                  return form.setFieldError(
                    id,
                    "Please select the attachment that is less than 10 MB in size"
                  )
                }
                const sameFile = entryAttachments.find(
                  (attachment) => attachment.fileName === files[0].name
                )
                if (sameFile?.fileName) {
                  setUploading({
                    id: `${generateUUID()}`,
                    fileName: sameFile.fileName,
                    mimeType: sameFile.mimeType,
                    url: sameFile.url,
                    file: files[0],
                  })
                  return sameAttachment.open()
                }
                form.setFieldError(id, undefined)
                form.setFieldTouched(field.name, true)
                form.setFieldValue(field.name, {
                  file: files[0],
                  fileName: files[0].name || "",
                  fileType: files[0].type || "",
                })
                readFileAsDataURL(files[0]).then((dataUrl) => {
                  setUploading({
                    id: `file_${generateUUID()}`,
                    fileName: files[0].name,
                    mimeType: files[0].type,
                    url: dataUrl as string,
                    file: files[0],
                  })
                })
                onSelectionEnd?.()
              }
            }}
          />
          {!entryAttachments?.length && !(loading && uploading?.id) ? (
            <Stack gap="2">
              <InputLabel
                fieldId={id}
                onClick={() => setFileValue("")}
                className={getButtonClassName({ ...labelButtonProps })}
              >
                <AttachmentIcon />
                Attach Bills
              </InputLabel>
              <Text fontSize="c3" color="textCashIn">
                Attach up to 4 images or PDF files
              </Text>
            </Stack>
          ) : (
            <Inline as="ul" gap="6">
              {entryAttachments.map((attachment, i) => {
                return (
                  <Box
                    as="li"
                    key={attachment.id}
                    size="16"
                    position="relative"
                    rounded="md"
                    borderWidth="1"
                    borderColor="borderOutline"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    onClick={() => {
                      openImagePreview(attachment)
                    }}
                  >
                    <Thumbnail
                      attachment={attachment}
                      onDelete={() => {
                        onDeleteIconClick(attachment)
                        setIsDeleting({ from: "delete" })
                      }}
                    />
                  </Box>
                )
              })}
              {loading && uploading?.id ? (
                <Box
                  as="li"
                  key={uploading.id}
                  size="16"
                  position="relative"
                  rounded="md"
                  borderWidth="1"
                  borderColor="borderOutline"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    rounded="full"
                    position="absolute"
                    as="button"
                    type="button"
                    disabled={loading}
                    className="-right-2 -top-2 bg-[white]"
                  >
                    <CancelFilledIcon color="iconHigh" size="4" />
                  </Box>
                  {uploading.mimeType?.includes("pdf") ? (
                    <PDFOutlineIcon size="8" color="iconPrimary" />
                  ) : (
                    <img
                      className="object-cover w-16 h-[62px] rounded"
                      src={uploading.thumbUrl || uploading.url}
                      alt={uploading.fileName}
                    />
                  )}
                  {loading ? (
                    <Box position="absolute">
                      <SpinnerIcon color="iconPrimary" />
                    </Box>
                  ) : null}
                  <Box
                    position="absolute"
                    width="full"
                    height="full"
                    top="0"
                    left="0"
                    rounded="md"
                    style={{
                      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0.66%, rgba(0, 0, 0, 0.36) 27.66%, rgba(255, 255, 255, 0.00) 97.40%)`,
                    }}
                  ></Box>
                  <Box
                    position="absolute"
                    bottom="0"
                    padding="1"
                    className="w-14"
                    color="textOnSurface"
                  >
                    <Text
                      style={{ fontSize: 10 }}
                      fontWeight="medium"
                      className="line-clamp-1"
                    >
                      {uploading.fileName}
                    </Text>
                  </Box>
                </Box>
              ) : null}
              {entryAttachments.length + (loading && uploading?.id ? 1 : 0) <
              maxFiles ? (
                <Box
                  fieldId={id}
                  size="16"
                  borderWidth="2"
                  rounded="md"
                  borderColor="borderPrimary"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className={classNames("border-dashed", {
                    "cursor-not-allowed": loading,
                  })}
                  as={InputLabel}
                  onClick={() => setFileValue("")}
                  cursor={loading ? "disabled" : "pointer"}
                >
                  <PlusIcon color="iconPrimary" />
                </Box>
              ) : null}
            </Inline>
          )}
          <Modal
            title={
              isDeleting?.from ? "Delete Attachment" : "Attachment Preview"
            }
            isOpen={imagePreviewState.isOpen}
            onClose={onCloseModal}
            onBackPress={
              isDeleting
                ? () => {
                    setIsDeleting(undefined)
                  }
                : undefined
            }
          >
            <ModalBody>
              <Stack gap="4">
                <Text fontSize="c3">{defaultFileOnPreview?.fileName}</Text>
                <Stack>
                  <Box paddingBottom="12">
                    {defaultFileOnPreview?.mimeType.includes("pdf") ? (
                      <iframe
                        title={defaultFileOnPreview.fileName}
                        src={defaultFileOnPreview.url}
                        width="100%"
                        height="600px"
                      ></iframe>
                    ) : (
                      <img
                        src={defaultFileOnPreview?.url}
                        alt="Preview"
                        className="w-full h-full max-w-full"
                      />
                    )}
                  </Box>
                </Stack>
              </Stack>
            </ModalBody>
            <ModalFooter position="relative">
              {isDeleting || entryAttachments?.length === 1 ? null : (
                <Inline
                  as="ul"
                  position="absolute"
                  gap="4"
                  width="full"
                  alignItems="center"
                  backgroundColor="surfaceNeutralLowest"
                  className="bottom-[100%]"
                  left="0"
                  paddingY="2"
                  paddingX="8"
                >
                  {entryAttachments.map((attachment) => {
                    const isSelected =
                      defaultFileOnPreview?.id === attachment.id
                    const isPdf = attachment.mimeType.includes("pdf")
                    return (
                      <Box
                        as="li"
                        size="10"
                        key={attachment.id}
                        borderWidth={isSelected ? "2" : "1"}
                        borderColor={
                          isSelected ? "borderPrimary" : "borderOutline"
                        }
                        onClick={() => {
                          setDefaultFileOnPreview(attachment)
                        }}
                        cursor="pointer"
                        rounded="md"
                        backgroundColor="surfaceDefault"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        className={classNames({
                          "hover:bg-blue-100": !isSelected && isPdf,
                        })}
                      >
                        <Box
                          className={classNames("opacity-0", {
                            "hover:opacity-100": !isSelected && !isPdf,
                          })}
                        >
                          <Box
                            position="absolute"
                            width="full"
                            height="full"
                            rounded="md"
                            top="0"
                            left="0"
                            style={{
                              background: `linear-gradient(0deg, rgba(33, 33, 33, 0.08) 0%, rgba(33, 33, 33, 0.08) 100%)`,
                            }}
                          ></Box>
                        </Box>
                        {isPdf ? (
                          <PDFOutlineIcon color="iconPrimary" size="8" />
                        ) : (
                          <img
                            className="w-9 h-9"
                            src={attachment.thumbUrl || attachment.url}
                            alt={attachment.fileName}
                          />
                        )}
                      </Box>
                    )
                  })}
                  <Box flex="1">
                    <Box display="flex" justifyContent="end">
                      <Button
                        inline
                        onClick={() => download("all")}
                        disabled={downloading || downloadingAll}
                      >
                        Download All
                      </Button>
                    </Box>
                  </Box>
                </Inline>
              )}
              {isDeleting?.from ? (
                <>
                  <Button
                    onClick={() => {
                      onRemove?.(defaultFileOnPreview?.id || "")
                      setUploading(undefined)
                      if (
                        entryAttachments.length === 1 ||
                        isDeleting.from === "delete"
                      ) {
                        setDefaultFileOnPreview(undefined)
                        imagePreviewState.close()
                        setIsDeleting(undefined)
                      } else {
                        setDefaultFileOnPreview(
                          defaultFileOnPreview?.id === entryAttachments[0].id
                            ? entryAttachments[1]
                            : entryAttachments[0]
                        )
                        setIsDeleting(undefined)
                      }
                      toast.success("Attachment deleted successfully")
                    }}
                    level="primary"
                    autoFocus
                    size="lg"
                    status="error"
                  >
                    <TrashIcon />
                    Yes, Delete
                  </Button>
                  <Button size="lg" onClick={onCloseModal}>
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => download("current")}
                    disabled={downloading || downloadingAll}
                    level="primary"
                    size="lg"
                  >
                    <DocumentDownloadIcon /> Download Attachment
                  </Button>
                  <Button
                    size="lg"
                    status="error"
                    onClick={() => {
                      onDeleteIconClick()
                      setIsDeleting({ from: "preview" })
                    }}
                  >
                    <TrashIcon />
                  </Button>
                </>
              )}
            </ModalFooter>
          </Modal>
          <Modal
            title="Duplicate Attachment"
            isOpen={sameAttachment.isOpen}
            onClose={sameAttachment.close}
          >
            <ModalBody>
              <Stack gap="4" fontSize="b3">
                <Text>Are you sure?</Text>
                <Text>
                  You want to attach the same bill again that was previously
                  attached.
                </Text>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                level="primary"
                size="lg"
                status="error"
                onClick={() => {
                  if (uploading) {
                    form.setFieldError(id, undefined)
                    form.setFieldTouched(field.name, true)
                    form.setFieldValue(field.name, {
                      file: uploading.file,
                      fileName: uploading.fileName || "",
                      fileType: uploading.mimeType || "",
                    })
                    onSelectionEnd?.()
                  }
                  sameAttachment.close()
                }}
              >
                Yes, Attach Anyway
              </Button>
              <Button size="lg" onClick={sameAttachment.close}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        </Box>
      )}
    />
  )
}
